import { useCallback } from 'react'

import * as S from './styles'

const RangeSlider: React.FC<RangeSliderProps> = ({
  min,
  max,
  value,
  onChange,
  readOnly = false,
  minLabel,
  maxLabel,
  size = 'medium'
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!readOnly) {
        onChange?.(Number(event.target.value))
      }
    },
    [onChange, readOnly]
  )

  return (
    <S.Container className="range-slider--container d-flex align-items-center gap-2">
      {minLabel && <span>{minLabel}</span>}
      <S.Slider
        className={`range-slider--slider ${readOnly ? 'read-only' : ''}`}
        data-size={size}
        type="range"
        min={min}
        max={max}
        onChange={handleChange}
        disabled={readOnly}
        readOnly={readOnly}
        {...(readOnly ? { value } : {})}
      />
      {maxLabel && <span>{maxLabel}</span>}
    </S.Container>
  )
}

export default RangeSlider
