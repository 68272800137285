import { forwardRef } from 'react'

import { Spinner } from '~/components/Spinner'

// import PropTypes from 'prop-types';

import { Keybind, Tooltip } from '../styles'

import * as S from './styles'

/**
 *
 * Renderiza os botões pesadrões do sistema. Estão separados por templates.
 *
 * @component
 * @example
 * <Button
 *   type="button"
 *   text="Salvar produto"
 *   template="primary"
 *   customWidth="auto"
 *   keybind="enter"
 *   className="btn-light-primary"
 *   onClick={() => {}}
 * />
 *
 *
 */

type ButtonType = React.ForwardRefRenderFunction<ContainerProps, ButtonProps>

const Button: ButtonType = (
  {
    text,
    template = 'primary',
    size = 'medium',
    type = 'button',
    customWidth,
    keybind,
    loading,
    className,
    disabled,
    iconLeft,
    iconRight,
    iconMobile,
    hideMobile,
    textLoading,
    tooltip,
    children,
    isSelected = false,
    ...rest
  },
  ref
) => (
  <S.Container
    ref={ref}
    disabled={loading || disabled}
    template={template}
    customWidth={customWidth}
    className={`button__${template}${className ? ` ${className}` : ''}`}
    iconMobile={!!iconMobile}
    type={type}
    hideMobile={hideMobile}
    size={size}
    isSelected={isSelected}
    {...rest}
  >
    {iconMobile && <span className="icon icon__mobile">{iconMobile}</span>}

    {iconLeft && <span className="icon icon__left">{iconLeft}</span>}

    {loading ? (
      <>
        <Spinner />
        {textLoading && <span className="text"> {textLoading}</span>}
      </>
    ) : (
      <span className="text">{text}</span>
    )}

    {children}

    {keybind && <Keybind className="keybind">{keybind}</Keybind>}

    {iconRight && <span className="icon icon__right">{iconRight}</span>}

    {tooltip && <Tooltip>{tooltip}</Tooltip>}
  </S.Container>
)

export default forwardRef(Button)

// const defaultProps = {
// template: 'primary',
// spinner: { color: 'secondary', type: 'grow', size: 'sm' },
// type: 'button',
// loading: false,
// disabled: false,
// customWidth: null,
// iconMobile: null,
// iconLeft: null,
// iconRight: null,
// text: null,
// keybind: null,
// className: null,
// hideMobile: false,
// textLoading: null,
// children: null,
// tooltip: null,
// };

// Button.defaultProps = defaultProps;
