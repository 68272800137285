import { css } from 'styled-components'

import { device } from '~/styles/device'

import { ButtonDefaultTemplateProps } from '.'

const sizeModifiers = {
  xsmall: () => css`
    height: 1.75em;
    font-size: 0.75rem;

    svg,
    .icon__right svg,
    .icon__left svg {
      width: 0.75rem;
    }

    &:focus {
      &:after {
        width: calc(100% + 0.25rem);
        height: calc(100% + 0.25rem);
        border-radius: 0.25rem;
        border: 1px solid var(--secondary);
      }
    }
  `,
  small: () => css`
    height: 2rem;
    padding: 0 0.875rem;
    font-size: 0.875rem;

    svg {
      width: 0.875rem;
    }
  `,
  medium: () => css``,
  large: ({ customWidth }: { customWidth?: string }) => css`
    height: 2.75rem;
    min-width: ${customWidth ? customWidth : '220px'};
  `
}

const defaultButtonTemplate = ({
  theme,
  props: {
    customWidth,
    $customWidth,
    hideMobile,
    $hideMobile,
    iconMobile,
    $iconMobile,
    size
  }
}: ButtonDefaultTemplateProps) => css`
  position: relative;

  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  background: transparent;
  border-radius: ${theme.border_radius};
  min-width: ${customWidth || $customWidth || '140px'};
  height: 2.375rem;
  padding: 0 1rem;

  font-size: 0.875rem;
  line-height: 1;

  transition: 0.2s ease-in-out all;

  /* reset */
  outline: none;
  border: none;
  text-decoration: none;

  @media ${device.tablet} {
    font-size: 1rem;
  }

  @media ${device.tablet} {
    font-size: 1rem;
  }

  @media ${device.mobileL_max} {
    display: ${hideMobile || $hideMobile ? 'none' : 'inline-flex'};
  }

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }

  form:invalid &[type='submit'],
  &:disabled {
    filter: saturate(75%);
    cursor: not-allowed;
  }

  .text {
    ${(iconMobile || $iconMobile) &&
    css`
      @media ${device.mobileL_max} {
        display: none;
      }
    `}
  }

  .icon {
    display: inline-flex;

    &__left {
      margin-right: 0.25rem;

      svg {
        width: 1.25rem;
      }
    }

    &__right {
      margin-left: 0.25rem;

      svg {
        width: 1.25rem;
      }
    }

    &__mobile {
      @media ${device.mobileL} {
        display: none;
      }
    }
  }

  // Hack para sobrepor as medias queries
  & {
    /* Modifier */
    ${sizeModifiers[size]({ customWidth: customWidth || $customWidth })};
  }

  &.button {
    @media ${device.mobileL_max} {
      &__flex1__mobile {
        flex: 1;
      }
    }
  }
`

export default defaultButtonTemplate
