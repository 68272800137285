import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'

import { useAppSelector } from '~/store/hooks'

import { Badge } from '../UI/Badge'
import { toggleSidebar } from './sidebar-actions'

const SidebarItem: React.FC<SidebarItemProps> = ({
  name,
  icon: Icon,
  to,
  childPaths,
  badgeColor,
  badgeText,
  hasChildren
}) => {
  const location = useLocation()
  const { pathname } = location

  const getSidebarItemClass = useCallback(() => {
    if (to === '/') {
      return pathname === to ? 'active' : ''
    }

    if (
      pathname.startsWith(to) ||
      childPaths?.some(childPath => pathname.startsWith(childPath))
    ) {
      return 'active'
    }

    return ''
  }, [childPaths, pathname, to])

  const getChildrenClass = hasChildren ? 'border-top pt-1 mt-1' : ''
  /**
   * Toggle mobile menu
   */

  const dispatch = useDispatch()

  const {
    layout: { isMobile }
  } = useAppSelector(state => state)

  const handleToggleSidebar = useCallback(() => {
    dispatch(toggleSidebar())
  }, [dispatch])

  return (
    <li className={`sidebar-item ${getChildrenClass} ${getSidebarItemClass()}`}>
      <NavLink
        to={to}
        className="sidebar-link"
        end="active"
        {...(isMobile ? { onClick: handleToggleSidebar } : {})}
      >
        {Icon ? <Icon size={12} className="align-middle me-2" /> : null}
        {name}
        {badgeText && <Badge template={badgeColor}>{badgeText}</Badge>}
      </NavLink>
    </li>
  )
}

export default SidebarItem
