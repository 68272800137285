import styled from 'styled-components'

export const Container = styled.div<AlertInfoProps>`
  --baseColor: var(--dark);
  --textColor: var(--neutralLowMediun);
  --marginIcon: 0.25rem;

  &[data-template='info'] {
    --baseColor: var(--info);
  }
  &[data-template='success'] {
    --baseColor: var(--success);
  }
  &[data-template='success_alert'] {
    --baseColor: var(--successAlert);
  }
  &[data-template='warning'] {
    --baseColor: var(--warning);
  }
  &[data-template='error'],
  &[data-template='danger'] {
    --baseColor: var(--danger);
  }
  &[data-template='default'] {
    --baseColor: var(--gray);
  }

  position: relative;

  display: flex;
  align-items: center;
  line-height: 1.25;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--baseColor);
  color: var(--textColor);
  background-color: var(--baseColor);

  .icon {
    display: inline-flex;

    svg {
      margin-right: var(--marginIcon);
      min-width: 1.5rem;
      color: var(--baseColor);
    }
  }

  /* Lighten BG */

  > * {
    position: relative;
    z-index: 1;
  }

  &:before {
    content: '';

    position: absolute;
    z-index: 0;

    top: 0;
    bottom: 0;
    right: 0;
    left: 0;

    border-radius: 0.25rem;
    background-color: rgba(255, 255, 255, 0.95);
  }
`
