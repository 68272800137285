import React, { useMemo } from 'react'

import { t } from 'i18next'
import PropTypes from 'prop-types'

import Pagination from '~/components/pagination'
import SelectSearchable from '~/components/SelectSearchable'
import { formatNumberWithDots } from '~/helpers'
import { useAppSelector } from '~/store/hooks'

import * as S from './styles'

const defaultOption = [10, 25, 50, 100, 250, 500]

const ListPagination = ({
  total,
  label = 'item',
  currentPage,
  itemsPerPage,
  menuPlacement = 'auto',
  onClickPagination,
  onItemsPerPageChange,
  customOptions = null
}) => {
  const { isMobile } = useAppSelector(state => state.layout)

  const generateSelectStructure = value => ({ value, label: value })

  const options = useMemo(() => {
    const data = [
      ...(customOptions?.length ? customOptions : defaultOption)
    ].map(generateSelectStructure)

    const itemsPerPageIsNotDefault = !defaultOption.includes(itemsPerPage)

    if (itemsPerPageIsNotDefault) {
      data.push(generateSelectStructure(itemsPerPage))
    }

    return data.sort((a, b) => a.value - b.value)
  }, [customOptions, itemsPerPage])

  const defaultValue = useMemo(() => {
    return generateSelectStructure(itemsPerPage)
  }, [itemsPerPage])

  const customStyles = {
    control: provided => ({
      ...provided,
      width: 100,
      minHeight: '33px'
    })
  }

  return (
    options[0].value < total && (
      <S.PaginationContainer>
        <S.SelectContainer>
          <S.ProductsCount>
            <strong>{formatNumberWithDots(total)}</strong> {label}
          </S.ProductsCount>

          <label>
            {t('common:table.pagination.show')}
            <SelectSearchable
              styles={customStyles}
              options={options}
              onChange={onItemsPerPageChange}
              isClearable={false}
              defaultValue={[defaultValue]}
              value={[defaultValue]}
              menuPlacement={menuPlacement}
              size="small"
            />
            {t('common:table.pagination.perPage')}
          </label>
        </S.SelectContainer>

        {total > itemsPerPage && (
          <Pagination
            count={total}
            page={currentPage}
            limit={itemsPerPage}
            onClickPagination={onClickPagination}
            maxPagesToShow={isMobile ? 5 : 10}
            hasFirstLast
            hasPagination
            alignCenter
          />
        )}
      </S.PaginationContainer>
    )
  )
}

export default ListPagination

ListPagination.propTypes = {
  total: PropTypes.number.isRequired,
  label: PropTypes.string,
  currentPage: PropTypes.number,
  itemsPerPage: PropTypes.number.isRequired,
  menuPlacement: PropTypes.string,
  onClickPagination: PropTypes.func.isRequired,
  onItemsPerPageChange: PropTypes.func.isRequired
}

ListPagination.defaultProps = {
  menuPlacement: 'bottom',
  label: 'item',
  currentPage: null
}
