import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux'

import SelectSearchable from '~/components/SelectSearchable'
import { getAccountType } from '~/helpers'
import i18n from '~/i18n'
import { useAppSelector } from '~/store/hooks'
import { setFilteredUserRoles } from '~/store/modules/filters/actions'

const { t } = i18n

/**
 * Componente para filtrar a listagem por permissões de usuário.
 * Ele possibilita a seleção de uma permissão e mantém no estado global: filters.userRoles.
 *
 * Não recebe nenhum parâmetro e retorna um select procurável.
 *
 * @example <FilterUserRoles />
 */

export const userRolesOptions = [
  { label: t('users:allPermissions'), value: null },
  {
    label: t('users:admin'),
    value: 'admin'
  },
  {
    label: t('users:user'),
    value: 'user'
  },
  {
    label: t('users:read_only'),
    value: 'read_only'
  },
  {
    label: t('users:guest'),
    value: 'guest'
  },
  {
    label: t('users:agent'),
    value: 'agent'
  },
  {
    label: t('users:carrier'),
    value: 'carrier'
  }
]

type FilterUserRolesProps = {
  onChange?: (data: OnChangeInputData) => void
  name?: string
  initialValue?: UserRoles
  size?: ReactSelectSizes
  ignoreRedux?: boolean
}

const FilterUserRoles = ({
  onChange = null,
  name = 'role',
  initialValue = null,
  ignoreRedux = false,
  ...rest
}: FilterUserRolesProps) => {
  const dispatch = useDispatch()

  const { userRoles: filteredUserRoles } = useAppSelector(
    state => state.filters
  )

  const handleChangeUserRole = (selectedUserRoles: { value: UserRoles }) => {
    if (onChange) {
      onChange({
        value: selectedUserRoles?.value || null,
        name
      })
    }

    if (ignoreRedux) return

    dispatch(setFilteredUserRoles({ filteredUserRoles: selectedUserRoles }))
  }

  const options = useMemo(() => {
    if (getAccountType() === 'grocery') {
      return userRolesOptions
    }

    // only admin, user and read_only
    return userRolesOptions.filter(item =>
      ['admin', 'user', 'read_only'].includes(item.value)
    )
  }, [])

  const defaultValue =
    initialValue || ignoreRedux
      ? options.find(({ value }) => value === initialValue)
      : [filteredUserRoles]

  return (
    <SelectSearchable
      placeholder={t('users:filterByPermissions')}
      options={options}
      onChange={handleChangeUserRole}
      defaultValue={defaultValue}
      name={name}
      {...rest}
    />
  )
}

export default FilterUserRoles
