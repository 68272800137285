import styled, { DefaultTheme, css } from 'styled-components'

type ContainerProps = {
  inline?: boolean
  simple?: boolean
}

const containerModifiers = {
  inline: () => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 0.5rem;
  `,
  simple: () => css`
    display: flex;
    gap: 1rem;
  `
}

export const Container = styled.div<ContainerProps>`
  ${({ inline, simple }) => css`
    ${simple && containerModifiers.simple()}
    ${inline && containerModifiers.inline()}
  `}
`

/**
 * Item
 */
export type RadioItemSizes = 'tiny' | 'small' | 'medium'

type ItemProps = {
  size?: RadioItemSizes
  hasActiveChildren?: boolean
  isSelected?: boolean
}

const itemModifiers = {
  hasActiveChildren: () => css``,
  isSelected: () => css`
    box-shadow: 0px 4px 8px rgba(24, 21, 60, 0.08);
  `,
  activeChildren: () => css`
    label {
      margin-bottom: 0;
    }
  `,
  size: {
    // Deve virar um template
    tiny: (theme: DefaultTheme) => css`
      --padding: 0 0 0 1.25rem;
      display: inline-flex;
      box-shadow: none !important;

      label {
        background: transparent !important;
        border: none !important;

        .label {
          font-size: 0.875rem;
          font-weight: normal;
        }

        &:before,
        &:after {
          left: 0.4rem;
        }
      }
    `,
    small: (theme: DefaultTheme) => css`
      --padding: ${theme.spacings.xxxsmall} ${theme.spacings.small}
        ${theme.spacings.xxxsmall} 3rem;
    `,
    medium: (theme: DefaultTheme) => css`
      --padding: ${theme.spacings.medium} ${theme.spacings.small}
        ${theme.spacings.medium} 3rem;
    `
  }
}

export const Item = styled.div<ItemProps>`
  ${({ theme, hasActiveChildren, isSelected, size = 'medium' }) => css`
    --borderColor: ${theme.colors.hover};
    --backgroundColor: ${theme.colors.neutral.high.lightest};
    /* Controlando layers para o activeChildren  */
    position: relative;
    z-index: 2;
    box-shadow: 0;

    &.disabled {
      filter: grayscale(0.5);
      cursor: not-allowed;

      * {
        cursor: not-allowed;
      }

      label {
        &:hover {
          color: inherit;
        }
      }
    }

    &.hide,
    input[type='radio'] {
      display: none;
    }

    /* Input */
    input[type='radio']:checked,
    &.selected input[type='radio'] {
      /* The .selected class is used in the radio list */
      + label {
        border-color: var(--borderColor);
        background-color: var(--backgroundColor);

        &:before {
          border-color: var(--borderColor);
        }

        &:after {
          width: ${theme.spacings.xxsmall};
          height: ${theme.spacings.xxsmall};
        }
      }

      + label {
        color: ${theme.colors.primary};

        strong {
          color: ${theme.colors.primary};
        }
        /* span {
          color: ${theme.colors.dark};
        } */
      }
    }

    /* Label */
    label {
      position: relative;

      display: flex;
      align-items: center;
      flex: auto;
      flex-wrap: wrap;

      border-radius: ${theme.border.radius.medium};
      padding: var(--padding);
      height: 100%;

      border: 1px solid ${theme.colors.inative};
      background-color: ${theme.colors.light};

      transition: all ${theme.transition.fast};
      cursor: pointer;

      &:hover {
        background-color: ${theme.colors.neutral.high.lightest};
      }

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 1.6rem;
        transform: translate(-50%, -50%);
        border-radius: ${theme.border.radius.rounded};
        transition:
          all ${theme.transition.fast},
          height ${theme.transition.fast};
      }

      &:before {
        width: ${theme.spacings.large};
        height: ${theme.spacings.large};
        border: 2px solid ${theme.colors.primary};

        background-color: ${theme.colors.white};
      }

      &:after {
        width: 0;
        height: 0;

        background: ${theme.colors.success};
      }
    }

    ${hasActiveChildren && isSelected && itemModifiers.activeChildren()}
    ${isSelected && itemModifiers.isSelected()}
    ${itemModifiers.size[size](theme)}
  `}
`

export const LabelWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    font-weight: 700;
    color: ${theme.colors.dark};
  `}
`

export const Icon = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    margin-right: 0.25rem;

    font-weight: 700;

    font-size: ${theme.font.sizes.small};
  `}
`

export const LabelText = styled.div`
  display: flex;
  align-items: center;

  font-weight: 700;
`

export const Description = styled.div`
  ${({ theme }) => css`
    flex: 1 1 100%;
    line-height: 1.125;

    font-size: ${theme.font.sizes.small};
  `}
`

export const ActiveChildren = styled.div`
  ${({ theme }) => css`
    display: flex;
    margin-top: -${theme.spacings.medium};
    margin-bottom: 0.5rem; // Retornando MB default do label
    z-index: 1;

    border-radius: ${theme.border.radius.medium};
    padding: 2rem ${theme.spacings.medium} ${theme.spacings.medium}
      ${theme.spacings.medium};

    border: 1px solid ${theme.colors.tertiary};
    background-color: ${theme.colors.white};

    transition: all ${theme.transition.fast};
  `}
`
