import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useCallback
} from 'react'

import { useField } from '@unform/core'

import * as S from './styles'

const ChechboxList: React.FC<CheckboxInputProps> = ({
  name,
  options = [],
  inline,
  grid,
  itemSize = 'small',
  onChange,
  ...rest
}) => {
  const inputRefs = useRef<HTMLInputElement[]>([])
  const { fieldName, registerField, defaultValue } = useField(name)
  const [selectedItemValue, setSelectedItemValue] = useState<{
    [key: string]: boolean
  }>()

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        // Isso é feito porque quando removemos um item da lista sem fazer o rerender, o ref fica como null e quebra o app
        const validsInput = refs.filter(item => !!item)

        const checkedInputs = validsInput.filter(ref => ref?.checked)

        return {
          all: validsInput.map(item => ({
            name: item.name,
            checked: item.checked,
            value: item.value
          })),
          allObject: validsInput.reduce(
            (acc, item) => ({ ...acc, [item.name]: item.checked }),
            {}
          ),
          filtered: checkedInputs?.map(item => item.name) || []
        } as CheckboxListReturn
      },
      clearValue: (refs: HTMLInputElement[]) => {
        refs.forEach(ref => {
          ref.checked = false
        })
      },
      setValue: (
        refs: HTMLInputElement[],
        values: { [key: string]: boolean }
      ) => {
        refs.forEach(ref => {
          if (Object.prototype.hasOwnProperty.call(values, ref.name)) {
            ref.checked = values[ref.name]
          }
        })
      }
    })
  }, [defaultValue, fieldName, registerField])

  useLayoutEffect(() => {
    setSelectedItemValue(defaultValue)
  }, [defaultValue])

  const handleChange = useCallback(
    (data: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(data)

      const { checked, name } = data.target

      setSelectedItemValue(current => ({ ...current, [name]: checked }))
    },
    [onChange]
  )

  return (
    <S.Container className="checkboxListWrapper" inline={inline} grid={grid}>
      {options.map((option, index) => (
        <React.Fragment key={`${name}${option.name}`}>
          <S.Item
            hasActiveChildren={!!option?.activeChildren}
            isSelected={!!selectedItemValue?.[option.name]}
            size={itemSize}
            className={`checkboxFieldWrapper ${
              option?.disabled ? 'disabled' : ''
            }`}
          >
            <input
              defaultChecked={defaultValue?.[option.name]}
              ref={ref => {
                inputRefs.current[index] = ref as HTMLInputElement
              }}
              type="checkbox"
              id={option?.id || `${name}${option.name}`}
              name={option.name}
              value={option.value}
              disabled={option?.disabled}
              onChange={handleChange}
              {...rest}
            />

            <label
              htmlFor={option.id || `${name}${option.name}`}
              title={option?.title}
            >
              <S.LabelWrapper className="labelWrapper">
                {option.icon && <S.Icon className="icon">{option.icon}</S.Icon>}
                {option.label && (
                  <>
                    <S.LabelText className="label">{option.label}</S.LabelText>
                    <div className="sideRight">{option.sideRight}</div>
                  </>
                )}
              </S.LabelWrapper>

              {option.description && (
                <S.Description>{option.description}</S.Description>
              )}
            </label>
          </S.Item>

          {option?.activeChildren && !!selectedItemValue?.[option.name] && (
            <S.ActiveChildren>{option.activeChildren}</S.ActiveChildren>
          )}
        </React.Fragment>
      ))}
    </S.Container>
  )
}

export default ChechboxList
