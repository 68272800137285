import React, { useEffect, useState } from 'react'
import { IconType } from 'react-icons'

import { checkRules } from './helpers'
import SidebarCategory from './SidebarCategory'
import SidebarItem from './SidebarItem'

// import { Container } from './styles';

type ChildrenProps = {
  route: RootMenuItem<IconType>
  pathName: string
}

const Children: React.FC<ChildrenProps> = ({ route, pathName }) => {
  const [toggle, setToggle] = useState(false)

  // const pathName = location.pathname // FOI

  useEffect(() => {
    const isActive = pathName.indexOf(route.path) === 0
    const isOpen = route.open
    const isHome = !!(route.containsHome && pathName === '/')

    setToggle(isActive || isOpen || isHome)
  }, [pathName, route.containsHome, route.open, route.path])

  return (
    <SidebarCategory
      name={route.name}
      badgeColor={route.badgeColor}
      badgeText={route.badgeText}
      icon={route.icon}
      to={route.path}
      isOpen={toggle}
      onClick={() => setToggle(currentState => !currentState)}
      withSeparator={route.withSeparator}
    >
      {route.children.map((child, index) => {
        if (checkRules(child) && !child.hideMenu) {
          return (
            <SidebarItem
              key={`item-${index}`}
              name={child.name}
              to={child.path}
              childPaths={child?.childPaths}
              badgeColor={child.badgeColor}
              badgeText={child.badgeText}
              hasChildren={child.hasChildren}
            />
          )
        }
      })}
    </SidebarCategory>
  )
}

export default Children
