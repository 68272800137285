import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: var(--font-size-2xsm);
    color: var(--neutralLowMediun);
  }
`

export const Slider = styled.input<StyledSliderProps>`
  -webkit-appearance: none;
  width: 100%;
  background-color: transparent;

  --lowColor: #ff8676;
  --midColor: #58d764;
  --highColor: #ffcd76;
  --size: 0.5rem;

  &[data-size='small'] {
    --size: 0.25rem;
    --width: 7rem;
  }

  width: var(--width, auto);

  // Estilos para WebKit (Chrome, Safari)
  &::-webkit-slider-thumb {
    transition: all 0.2s ease-in-out;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    outline: 2px solid #fff;
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
    box-shadow:
      0px 6px 8px rgba(0, 0, 0, 0.06),
      0px 3px 3px rgba(0, 0, 0, 0.1);
  }

  &:focus::-webkit-slider-thumb {
    outline-width: 3px;
    box-shadow:
      0px 6px 8px 3px rgba(0, 0, 0, 0.06),
      0px 3px 3px 3px rgba(0, 0, 0, 0.1);
  }

  &::-webkit-slider-runnable-track {
    background: linear-gradient(
      to right,
      var(--lowColor) 0%,
      var(--midColor) 20%,
      var(--midColor) 80%,
      var(--highColor) 100%
    );
    height: var(--size);
    border-radius: calc(var(--size) / 2);
  }

  // Estilos para Firefox
  &::-moz-range-thumb {
    background-color: transparent;
    border: none;
    outline: 2px solid #fff;
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
    box-shadow:
      0px 6px 8px rgba(0, 0, 0, 0.06),
      0px 3px 3px rgba(0, 0, 0, 0.1);
  }

  &::-moz-range-track {
    background: linear-gradient(
      to right,
      var(--lowColor) 0%,
      var(--midColor) 20%,
      var(--midColor) 80%,
      var(--highColor) 100%
    );
    height: var(--size);
    border-radius: calc(var(--size) / 2);
  }

  // Estilos para Internet Explorer/Edge
  &::-ms-thumb {
    background-color: transparent;
    border: none;
    outline: 2px solid #fff;
    border-radius: 50%;
    width: var(--size);
    height: var(--size);
    transform: translateY(-25%);
    box-shadow:
      0px 6px 8px rgba(0, 0, 0, 0.06),
      0px 3px 3px rgba(0, 0, 0, 0.1);
  }

  &::-ms-track {
    background: transparent;
    border-color: transparent;
    color: transparent;
    height: 8px;
  }

  &::-ms-fill-lower {
    background: linear-gradient(
      to right,
      var(--lowColor) 0%,
      var(--midColor) 20%,
      var(--midColor) 80%,
      var(--highColor) 100%
    );
    border-radius: 4px;
  }

  &::-ms-fill-upper {
    background: linear-gradient(
      to right,
      var(--lowColor) 0%,
      var(--midColor) 20%,
      var(--midColor) 80%,
      var(--highColor) 100%
    );
    border-radius: 4px;
  }
`
